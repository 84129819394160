import React from 'react';

import Layout from '../components/body/layout';
import Head from '../components/header/head';



const NotFound = () => {
    return (
        <Layout>
            <Head title="404 error" />
            <h1>Page 404 Error</h1>
        </Layout>
    )
}

export default NotFound;